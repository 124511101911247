import * as EnvConfig from "./env-config";

import { NavConfirmation, PayloadData } from "@epic/doc-platform-components";
import React, { Fragment, Suspense, useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { Spinner, SpinnerSize, Stack } from "@fluentui/react";

import { Context } from "@epic/doc-platform-context";
import { DigitalOpsHome } from "./digital-ops-home/digital-ops-home";
import { gql } from "apollo-boost";
import { useApolloClient } from "@apollo/react-hooks";

/**
 * The content to display for the App Platform.
 * @returns A JSX.Element.
 */
export const AppPlatformContent: React.FunctionComponent = (): JSX.Element => {
  const contextHook = useContext(Context);
  const client = useApolloClient();

  const siteTitle = contextHook.context.environment === "prod" ? "DOC" : `DOC(${contextHook.context.environment})`;

  // Run on initial mount
  useEffect(() => {
    contextHook.authentication.generateHeaders().then((headers) => {
      client
        .query({
          query: gql`
            query getTenantVersions {
              getTenantVersions
            }
          `,
          fetchPolicy: "network-only",
          context: headers
        })
        .then((response: PayloadData) => {
          console.log(response?.data?.getTenantVersions);
        });
    });
  }, []);

  return (
    <Fragment>
      <Suspense
        fallback={
          <Stack className="homeSpinner" verticalAlign="center">
            <Stack.Item>
              <Spinner size={SpinnerSize.large} />
            </Stack.Item>
          </Stack>
        }
      >
        <Switch>
          <Route
            path="/contoso"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/contoso`;
              const hash = window.location.hash.replace("/contoso", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/launch"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/mila`;
              const hash = window.location.hash.replace("/launch", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/deal"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/deal`;
              const hash = window.location.hash.replace("/deal", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/pslip"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/pslip`;
              const hash = window.location.hash.replace("/pslip", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/wfm"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/wfm`;
              const hash = window.location.hash.replace("/wfm", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/qubit"
            ref={(): void => {
              const rootUrl = `${EnvConfig.UI_REDIRECTION_ORIGIN}/qubit`;
              const hash = window.location.hash.replace("/qubit", "");
              const queryString = window.location.search;
              const url = `${rootUrl}/${hash}${queryString}`;
              window.open(url, "_self");
            }}
          />
          <Route
            path="/"
            component={DigitalOpsHome}
            ref={(): void => {
              document.title = `${siteTitle}`;
            }}
          />
        </Switch>
      </Suspense>
      <NavConfirmation
        showDialog={contextHook.context.showNavConfirmationDialog}
        onOk={(): void => {
          contextHook.contextMutations.redirectUnsafe(contextHook.context.navPath, contextHook.context.navExtension);
        }}
        onClose={(): void => {
          contextHook.contextMutations.hideNavigationInterrupt();
        }}
      />
    </Fragment>
  );
};
